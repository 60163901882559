var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "orderTest-container" },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: {
            model: _vm.form,
            inline: "",
            "label-position": "right",
            "label-width": "100px",
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "", prop: "" } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "140px" },
                  attrs: { clearable: "", placeholder: "下单时间" },
                  model: {
                    value: _vm.form.time_type,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "time_type", $$v)
                    },
                    expression: "form.time_type",
                  },
                },
                _vm._l(_vm.orderTime, function (i, idx) {
                  return _c("el-option", {
                    key: idx,
                    attrs: { value: i.id, label: i.name },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "", prop: "" } },
            [
              _c("el-date-picker", {
                attrs: {
                  type: "daterange",
                  "range-separator": "-",
                  "value-format": "yyyy-MM-dd",
                  "start-placeholder": "开始日期",
                  "end-placeholder": "结束日期",
                },
                model: {
                  value: _vm.time,
                  callback: function ($$v) {
                    _vm.time = $$v
                  },
                  expression: "time",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "", prop: "" } },
            [
              _c("sale-area", {
                ref: "saleArea",
                on: { "set-sale-select-id": _vm.changeSaleArea },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "", prop: "" } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "140px" },
                  attrs: { clearable: "", placeholder: "渠道" },
                  model: {
                    value: _vm.form.channel_id,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "channel_id", $$v)
                    },
                    expression: "form.channel_id",
                  },
                },
                _vm._l(_vm.channelList, function (i, idx) {
                  return _c("el-option", {
                    key: idx,
                    attrs: { value: i.id, label: i.name },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "", prop: "" } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "140px" },
                  attrs: { clearable: "", placeholder: "客户等级" },
                  model: {
                    value: _vm.form.grade_id,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "grade_id", $$v)
                    },
                    expression: "form.grade_id",
                  },
                },
                _vm._l(_vm.levelList, function (i, idx) {
                  return _c("el-option", {
                    key: idx,
                    attrs: { value: i.id, label: i.name },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "", prop: "" } },
            [
              _c("class-select", {
                ref: "classSelect",
                on: { "class-select-change": _vm.changeClass },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "", prop: "" } },
            [
              _c("brand-select", {
                ref: "brandSelect",
                attrs: { duoxuan: "" },
                on: { "brand-select-change": _vm.changeBrand },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "", prop: "" } },
            [
              _c("staff-select", {
                ref: "staffSelect",
                on: { "change-staff": _vm.changeStaff },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "", prop: "" } },
            [
              _c("driver-select", {
                ref: "driverSelect",
                on: { "change-driver": _vm.changeDriver },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "", prop: "" } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "140px" },
                  attrs: { clearable: "", placeholder: "出货仓库" },
                  model: {
                    value: _vm.form.depot_id,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "depot_id", $$v)
                    },
                    expression: "form.depot_id",
                  },
                },
                _vm._l(_vm.depotList, function (i, idx) {
                  return _c("el-option", {
                    key: idx,
                    attrs: { value: i.id, label: i.name },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "", prop: "" } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "130px" },
                  attrs: { clearable: "", placeholder: "订单来源" },
                  model: {
                    value: _vm.form.bill_type,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "bill_type", $$v)
                    },
                    expression: "form.bill_type",
                  },
                },
                _vm._l(_vm.orderFrom, function (i, idx) {
                  return _c("el-option", {
                    key: idx,
                    attrs: { value: i.id, label: i.name },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "", prop: "" } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "130px" },
                  attrs: { clearable: "", placeholder: "订单状态" },
                  model: {
                    value: _vm.form.bill_status,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "bill_status", $$v)
                    },
                    expression: "form.bill_status",
                  },
                },
                _vm._l(_vm.orderStatus, function (i, idx) {
                  return _c("el-option", {
                    key: idx,
                    attrs: { value: i.id, label: i.name },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "", prop: "" } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "140px" },
                  attrs: { clearable: "", placeholder: "是否结清" },
                  model: {
                    value: _vm.form.pay_status,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "pay_status", $$v)
                    },
                    expression: "form.pay_status",
                  },
                },
                _vm._l(_vm.payStatusList, function (i, idx) {
                  return _c("el-option", {
                    key: idx,
                    attrs: { value: i.id, label: i.name },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "", prop: "" } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "140px" },
                  attrs: { clearable: "", placeholder: "自定义客户分组" },
                  model: {
                    value: _vm.form.cust_group_id,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "cust_group_id", $$v)
                    },
                    expression: "form.cust_group_id",
                  },
                },
                _vm._l(_vm.customClient, function (i, idx) {
                  return _c("el-option", {
                    key: idx,
                    attrs: { value: i.id, label: i.name },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "", prop: "" } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "140px" },
                  attrs: { clearable: "", placeholder: "自定义商品分组" },
                  model: {
                    value: _vm.form.goods_group_id,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "goods_group_id", $$v)
                    },
                    expression: "form.goods_group_id",
                  },
                },
                _vm._l(_vm.customGoods, function (i, idx) {
                  return _c("el-option", {
                    key: idx,
                    attrs: { value: i.id, label: i.name },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "", prop: "" } },
            [
              _c("goods-search", {
                attrs: { "is-table": false },
                on: { "select-goods-all": _vm.selectGoods },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "", prop: "" } },
            [
              _c("client-search", {
                on: { "select-val-alldata": _vm.selectClient },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "", prop: "" } },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.handleQuery } },
                [_vm._v("查询")]
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "", prop: "" } },
            [
              _c("el-button", { on: { click: _vm.handleExport } }, [
                _vm._v("导出"),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          attrs: {
            stripe: "",
            data: _vm.list,
            "span-method": _vm.mergeColumns,
          },
        },
        [
          _c("el-table-column", {
            attrs: { align: "center", type: "index", width: "50" },
          }),
          _vm._l(_vm.finallyColumns, function (item, tableIndex) {
            return _c("el-table-column", {
              key: tableIndex,
              attrs: {
                prop: item.prop,
                label: item.label,
                width: "",
                align: "center",
              },
            })
          }),
        ],
        2
      ),
      _c("el-pagination", {
        attrs: {
          "current-page": _vm.form.pageNo,
          layout: _vm.layout,
          "page-size": _vm.form.pageSize,
          total: _vm.total,
          background: "",
        },
        on: {
          "current-change": _vm.handleCurrentChange,
          "size-change": _vm.handleSizeChange,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }