<template>
  <div class="orderTest-container">
    <el-form
      ref="form"
      :model="form"
      inline
      label-position="right"
      label-width="100px"
    >
      <el-form-item label="" prop="">
        <el-select
          v-model="form.time_type"
          clearable
          style="width: 140px"
          placeholder="下单时间"
        >
          <el-option
            v-for="(i, idx) in orderTime"
            :key="idx"
            :value="i.id"
            :label="i.name"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="" prop="">
        <el-date-picker
          v-model="time"
          type="daterange"
          range-separator="-"
          value-format="yyyy-MM-dd"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
        ></el-date-picker>
      </el-form-item>
      <el-form-item label="" prop="">
        <sale-area
          ref="saleArea"
          @set-sale-select-id="changeSaleArea"
        ></sale-area>
      </el-form-item>
      <el-form-item label="" prop="">
        <el-select
          v-model="form.channel_id"
          clearable
          style="width: 140px"
          placeholder="渠道"
        >
          <el-option
            v-for="(i, idx) in channelList"
            :key="idx"
            :value="i.id"
            :label="i.name"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="" prop="">
        <el-select
          v-model="form.grade_id"
          clearable
          style="width: 140px"
          placeholder="客户等级"
        >
          <el-option
            v-for="(i, idx) in levelList"
            :key="idx"
            :value="i.id"
            :label="i.name"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="" prop="">
        <class-select
          ref="classSelect"
          @class-select-change="changeClass"
        ></class-select>
      </el-form-item>
      <el-form-item label="" prop="">
        <brand-select
          ref="brandSelect"
          duoxuan
          @brand-select-change="changeBrand"
        ></brand-select>
      </el-form-item>
      <el-form-item label="" prop="">
        <staff-select
          ref="staffSelect"
          @change-staff="changeStaff"
        ></staff-select>
      </el-form-item>
      <el-form-item label="" prop="">
        <driver-select
          ref="driverSelect"
          @change-driver="changeDriver"
        ></driver-select>
      </el-form-item>
      <el-form-item label="" prop="">
        <el-select
          v-model="form.depot_id"
          clearable
          style="width: 140px"
          placeholder="出货仓库"
        >
          <el-option
            v-for="(i, idx) in depotList"
            :key="idx"
            :value="i.id"
            :label="i.name"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="" prop="">
        <el-select
          v-model="form.bill_type"
          clearable
          style="width: 130px"
          placeholder="订单来源"
        >
          <el-option
            v-for="(i, idx) in orderFrom"
            :key="idx"
            :value="i.id"
            :label="i.name"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="" prop="">
        <el-select
          v-model="form.bill_status"
          clearable
          style="width: 130px"
          placeholder="订单状态"
        >
          <el-option
            v-for="(i, idx) in orderStatus"
            :key="idx"
            :value="i.id"
            :label="i.name"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="" prop="">
        <el-select
          v-model="form.pay_status"
          clearable
          style="width: 140px"
          placeholder="是否结清"
        >
          <el-option
            v-for="(i, idx) in payStatusList"
            :key="idx"
            :value="i.id"
            :label="i.name"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="" prop="">
        <el-select
          v-model="form.cust_group_id"
          clearable
          style="width: 140px"
          placeholder="自定义客户分组"
        >
          <el-option
            v-for="(i, idx) in customClient"
            :key="idx"
            :value="i.id"
            :label="i.name"
          ></el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="" prop="">
        <el-select
          v-model="form.goods_group_id"
          clearable
          style="width: 140px"
          placeholder="自定义商品分组"
        >
          <el-option
            v-for="(i, idx) in customGoods"
            :key="idx"
            :value="i.id"
            :label="i.name"
          ></el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="" prop="">
        <goods-search
          :is-table="false"
          @select-goods-all="selectGoods"
        ></goods-search>
      </el-form-item>
      <el-form-item label="" prop="">
        <client-search @select-val-alldata="selectClient"></client-search>
      </el-form-item>
      <el-form-item label="" prop="">
        <el-button type="primary" @click="handleQuery">查询</el-button>
      </el-form-item>
      <el-form-item label="" prop="">
        <el-button @click="handleExport">导出</el-button>
      </el-form-item>
    </el-form>
    <el-table
      v-loading="loading"
      stripe
      :data="list"
      :span-method="mergeColumns"
    >
      <!-- 序号 -->
      <el-table-column align="center" type="index" width="50">
        <!-- <template slot="header">
          <el-popover popper-class="custom-table-checkbox" trigger="hover">
            <el-checkbox-group v-model="checkList">
              <el-checkbox
                v-for="(item, index) in columns"
                :key="index"
                :label="item.label"
              ></el-checkbox>
            </el-checkbox-group>
            <el-button slot="reference" type="text">
              <vab-remix-icon icon="settings-line" />
            </el-button>
          </el-popover>
        </template> -->
      </el-table-column>
      <el-table-column
        v-for="(item, tableIndex) in finallyColumns"
        :key="tableIndex"
        :prop="item.prop"
        :label="item.label"
        width=""
        align="center"
      ></el-table-column>
    </el-table>
    <el-pagination
      :current-page="form.pageNo"
      :layout="layout"
      :page-size="form.pageSize"
      :total="total"
      background
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    ></el-pagination>
  </div>
</template>
<script>
  import _ from 'lodash'
  import GoodsSearch from '@/baseComponents/goodsSearch'
  import ClientSearch from '@/baseComponents/clientSearch'
  import ClassSelect from '@/baseComponents/classSelect'
  import SaleArea from '@/baseComponents/saleAreaSelectTree'
  import BrandSelect from '@/baseComponents/brandSelect'
  import StaffSelect from '@/baseComponents/staffSelect'
  import DriverSelect from '@/baseComponents/driverSelect'
  import { downloadFile, postAction } from '@/api/Employee'
  import { getClientChannelList, getClientLevelList } from '@/api/setPrice'
  import {day_n} from "@/utils/Time";

  export default {
    name: 'ClientSummary',
    components: {
      GoodsSearch,
      ClientSearch,
      ClassSelect,
      BrandSelect,
      StaffSelect,
      DriverSelect,
      SaleArea,
    },
    data() {
      return {
        loading: false,
        list: [],
        time: [],
        form: {
          pageSize: 10,
          pageNo: 1,
          time_type: 'create_at',
          start_time: '',
          end_time: '',
        },
        levelList: [],
        channelList: [],
        // 出货仓库下拉
        depotList: [],
        // 订单来源 1、车销 2、访销 3、网销 4、仓库直
        orderFrom: [
          // {
          //   id: 1,
          //   name: '车销',
          // },
          {
            id: 2,
            name: '访销',
          },
          {
            id: 3,
            name: '网销',
          },
          // {
          //   id: 4,
          //   name: '仓库直销',
          // },
        ],
        orderStatus: [
          {
            id: -1,
            name: '待审核',
          },
          {
            id: 1,
            name: '待派单',
          },
          {
            id: 2,
            name: '已派单',
          },
          {
            id: 3,
            name: '已到货',
          },
          {
            id: 4,
            name: '已作废',
          },
        ],
        orderTime: [
          {
            id: 'create_at',
            name: '下单时间',
          },
          {
            id: 'arrive_at',
            name: '到货时间',
          },
          {
            id: 'pay_status_time',
            name: '结清时间',
          },
          {
            id: 'delivery_at',
            name: '派送时间',
          },
        ],
        payStatusList: [
          {
            id: -1,
            name: '未结清',
          },
          {
            id: 1,
            name: '已结清',
          },
          {
            id: 2,
            name: '坏账',
          },
        ],
        customClient: [],
        customGoods: [],
        total: 0,
        layout: 'total, sizes, prev, pager, next, jumper',
        checkList: [
          '客户编码',
          '客户名称',
          '商品编码',
          '商品名称',
          '规格',
          '单位换算',
          '类型',
          '下单数量',
          '到货数量',
          '单位',
          '单价',
          '金额',
          '毛利',
          '毛利率',
        ],
        columns: [
          {
            order: 1,
            label: '客户编码',
            prop: 'cust_id',
            width: '',
          },
          {
            order: 2,
            label: '客户名称',
            prop: 'cust_name',
            width: '',
          },
          {
            order: 3,
            label: '商品编码',
            prop: 'goods_id',
            width: '',
          },
          {
            order: 4,
            label: '商品名称',
            prop: 'goods_name',
            width: '',
          },
          {
            order: 5,
            label: '规格',
            prop: 'specs',
            width: '',
          },
          {
            order: 6,
            label: '单位换算',
            prop: 'goods_convert',
            width: '',
          },
          {
            order: 7,
            label: '类型',
            prop: 'goods_type_text',
            width: '',
          },
          {
            order: 7,
            label: '下单数量',
            prop: 'pre_quantity',
            width: '',
          },
          {
            order: 7,
            label: '到货数量',
            prop: 'quantity',
            width: '',
          },
          {
            order: 7,
            label: '单位',
            prop: 'unit_name',
            width: '',
          },
          {
            order: 7,
            label: '单价',
            prop: 'goods_price',
            width: '',
          },
          {
            order: 7,
            label: '金额',
            prop: 'goods_money',
            width: '',
          },
          {
            order: 7,
            label: '毛利',
            prop: 'gross_profit_amount',
            width: '',
          },
          {
            order: 7,
            label: '毛利率',
            prop: 'gross_profit',
            width: '',
          },
        ],
      }
    },
    computed: {
      finallyColumns() {
        let finallyArray = []
        this.checkList.forEach((checkItem) => {
          finallyArray.push(
            this.columns.filter((item) => item.label === checkItem)[0]
          )
        })
        return _.sortBy(finallyArray, (item) => item.order)
      },
    },
    watch: {
      time(val) {
        if (val) {
          this.form.start_time = val[0]
          this.form.end_time = val[1]
        } else {
          this.form.start_time = ''
          this.form.end_time = ''
        }
      },
    },
    created() {},
    mounted() {
      this.time = day_n(90)
      this.form.start_time = this.time[0]
      this.form.end_time = this.time[1]
      this.initSelect()
      this.fetchData()
    },
    activated() {
      this.fetchData()
    },
    methods: {
      async fetchData() {
        this.loading = true
        let { data, totalCount } = await postAction(
          '/saleAdmin/sale-order-form/customer',
          this.form
        )
        this.total = totalCount
        this.list = data.list
        this.getSpanArr(this.list)
        this.loading = false
      },
      handleQuery() {
        this.form.pageSize = 10
        this.form.pageNo = 1
        this.fetchData()
      },
      getSpanArr(data, name) {
        let Arr = []
        let pos = 0
        for (var i = 0; i < data.length; i++) {
          if (i == 0) {
            Arr.push(1)
            pos = 0
          } else {
            // 判断当前元素与上一个元素是否相同
            if (eval('data[i].' + name + ' === data[i - 1].' + name)) {
              Arr[pos] += 1
              Arr.push(0)
            } else {
              Arr.push(1)
              pos = i
            }
          }
        }
        return Arr
      },
      mergeColumns({ row, column, rowIndex, columnIndex }) {
        if (columnIndex === 1 || columnIndex === 2 || columnIndex === 3) {
          let _row = this.getSpanArr(this.list, column.property)[rowIndex]
          let _col = _row > 0 ? 1 : 0
          return {
            // [0,0] 表示这一行不显示， [2,1]表示行的合并数
            rowspan: _row,
            colspan: _col,
          }
        }
      },
      // 获取下拉数据
      initSelect() {
        this.getDepot()
        this.clientGroup()
        this.goodsGroup()
        this.getChannelList()
        this.getLevelList()
      },
      // 客户等级下拉
      getLevelList() {
        getClientLevelList().then((r) => {
          this.levelList = r.data
        })
      },
      // 渠道下拉
      getChannelList() {
        getClientChannelList().then((r) => {
          this.channelList = r.data
        })
      },
      // 出货仓库下拉
      getDepot() {
        postAction('/saleAdmin/order-pre/create-option').then((r) => {
          console.log('ck', r)
          this.depotList = r.data.depot
        })
      },
      // 客户分组下拉
      clientGroup() {
        postAction('/baseAdmin/customer-group/index', { pageSize: -1 }).then(
          (r) => {
            this.customClient = r.data
          }
        )
      },
      // 商品分组下拉
      goodsGroup() {
        postAction('/baseAdmin/goods-group/index', { pageSize: -1 }).then(
          (r) => {
            this.customGoods = r.data
          }
        )
      },
      // 修改品类
      changeClass(v) {
        this.form.class_id = v
      },
      // 修改品牌
      changeBrand(v) {
        this.form.brand_id = v
      },
      // 修改业务员
      changeStaff(v) {
        this.form.staff_id = v
      },
      // 修改送货人
      changeDriver(v) {
        this.form.dirver_id = v
      },
      // 修改销售区域
      changeSaleArea(v) {
        this.form.area_id = v
      },
      selectGoods(v) {
        console.log('', v)
        if (v.goods_id > 0) this.form.goods_id = v.goods_id
        else this.form.goods_id = ''
      },
      selectClient(v) {
        this.form.cust_id = v.id
      },

      handleCurrentChange(val) {
        this.form.pageNo = val
        this.fetchData()
      },
      handleSizeChange(val) {
        this.form.pageSize = val
        this.fetchData()
      },

      handleExport() {
        downloadFile(
          '/saleAdmin/sale-order-form/export-customer',
          '预订单-销售汇总按客户.xlsx',
          this.form
        )
      },
    },
  }
</script>
<style lang="scss" scoped></style>
